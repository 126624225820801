import React from 'react'

import Layout from '../../components/layout'
import { SingleSection, SingleSectionGray } from '../../components/sections'
import { Col } from 'react-bootstrap'

const Leggi = () => (
<Layout frame='appNav'>

    <SingleSection id='leggio'>
        <Col md={12} className='text-center heading-section'>
            <h3>SCI nel codice delle obbligazioni</h3>
            <p>
                Verifica dell'esistenza di un SCI da parte dell'ufficio di revisione in casi di
                assoggettamento a revisione ordinaria.
                <a target='_blank' href='https://www.admin.ch/opc/it/classified-compilation/19110009/index.html#a728a' rel='noopener noreferrer'>(art. 728a cpv. 1 CO)</a>
            </p>
            <p>
                Reqisiti per l'assoggettazione a revisione ordinaria
                <a href='https://www.admin.ch/opc/it/classified-compilation/19110009/index.html#a727' target='_blank' rel='noopener noreferrer'>(art. 727 cpv. 1 CO)</a>:
            </p>
        </Col>
        <Col md={{ span: 6, offset: 3 }}>
            <ul className='group-heading'>
                <li>CHF 20 Mio. di somma di bilancio</li>
                <li>CHF 40 Mio. di cifra d'affari</li>
                <li>250 posti di lavoro a tempo pieno in media annua</li>
            </ul>
        </Col>
    </SingleSection>

    <SingleSectionGray id='leggif'>
        <Col md={12} className='text-center heading-section'>
            <h3>SCI nel diritto federale</h3>
            <p>
                Il consiglio federale adotta le misure necessarie per proteggere il patrimonio della
                confederazione, assicurare l'impiego appropriato dei fondi secondo i principi dell'art.
                12 cpv.4, evitare o scoprire errori e irregolarità nella tenuta dei conti e garantire
                il rispetto delle prescrizioni in materia di presentazione dei conti e l'affidabilità
                del rendiconto.
                <a target='_blank' href='https://www.admin.ch/opc/it/classified-compilation/20052508/index.html#a39' rel='noopener noreferrer'>(art. 39 cpv. 1 LFC)</a>
            </p>
            <p>
                Il sistema di controllo interno comprende misure regolative, organizzative e tecniche.
                <a target='_blank' href='https://www.admin.ch/opc/it/classified-compilation/20052508/index.html#a36' rel='noopener noreferrer'>(art. 36 cpv. 1 OFC)</a>
            </p>
            <p>
                L'Amministrazione delle finanze emana le istruzioni necessarie necessarie d'intesa con
                il Controllo delle finanze e consultati i dipartimenti.
                <a target='_blank' href='https://www.admin.ch/opc/it/classified-compilation/20052508/index.html#a36' rel='noopener noreferrer'>(art. 36 cpv. 2 OFC)</a>
            </p>
            <p>
                I direttori delle unità amministrative sono responsabili per l'introduzione, l'impiego
                e la sorveglianza del sistema di controllo nel settore di competenza.
                <a target='_blank' href='https://www.admin.ch/opc/it/classified-compilation/20052508/index.html#a36' rel='noopener noreferrer'>(art. 36 cpv. 3 OFC)</a>
            </p>
        </Col>
    </SingleSectionGray>

    <SingleSection id='leggic'>
        <Col md={12} className='text-center heading-section'>
            <h3>SCI nel diritto cantonale</h3>
            <p>
                Il controllo interno è costituito dall'insieme delle misure organizzative adottate
                dall'amministrazione allo scopo di assicurare
                l'applicazione dei principi di gestione finanziaria e della contabilità.
                <a target='_blank' href='https://www3.ti.ch/CAN/RLeggi/public/raccolta-leggi/legge/numero/2.5.1.1' rel='noopener noreferrer'>(art. 20 cpv. 1 LGF)</a>
            </p>
            <p>
                Il controllo interno riguarda le fasi di progettazione, di adozione e di esecuzione
                degli atti amministrativi.
                <a target='_blank' href='https://www3.ti.ch/CAN/RLeggi/public/raccolta-leggi/legge/numero/2.5.1.1' rel='noopener noreferrer'>(art. 20 cpv. 2 LGF)</a>
            </p>
            <p>
                Il controllo interno presuppone il monitoraggio della realizzazione degli obiettivi e
                un approccio orientato ai rischi dei
                relativi processi.
                <a target='_blank' href='https://www3.ti.ch/CAN/RLeggi/public/raccolta-leggi/legge/numero/2.5.1.1' rel='noopener noreferrer'>(art. 20 cpv. 3 LGF)</a>
            </p>
            <p>
                I singoli servizi sono in primo luogo competenti e responsabili dell'attuazione del
                controllo interno.
                <a target='_blank' href='https://www3.ti.ch/CAN/RLeggi/public/raccolta-leggi/legge/numero/2.5.1.1' rel='noopener noreferrer'>(art. 22 cpv. 1 LGF)</a>
            </p>
            <p>
                L'implementazione o la modifica di processi amministrativi, finanziari e informatici
                tiene conto delle normative legali,
                nonchè delle esigenze in materia di controllo interno e di revisione intena.
                <a target='_blank' href='https://www3.ti.ch/CAN/RLeggi/public/raccolta-leggi/legge/numero/2.5.1.1' rel='noopener noreferrer'>(art. 22a LGF)</a>
            </p>
        </Col>
    </SingleSection>

</Layout>
)

export default Leggi
